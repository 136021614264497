<template>
  <div class="row d-flex">
    <div class="col">
      <complete-displayer-preview
        v-if="editable"
        @addItem="addItem"
      ></complete-displayer-preview>
      <grid-layout
        :layout.sync="layout"
        :col-num="colNum"
        :row-height="75"
        :is-draggable="editable"
        :is-resizable="editable"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minH="item.configuration.minH"
          :minW="item.configuration.minW"
          :maxH="item.configuration.maxH"
          :maxW="item.configuration.maxW"
        >
          <signal
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 7"
            :isClickable="!editable"
          ></signal>
          <number
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 5 || item.item.displayModeId == 4"
            :isClickable="!editable"
          ></number>
          <battery
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 6"
            :isClickable="!editable"
          ></battery>
          <slot-graph
            :interval="{ start: dateFilter[0], end: dateFilter[1] }"
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 1"
            :isClickable="!editable"
          ></slot-graph>
          <heap-leach-displayer
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 11"
            :title="item.item.name"
            :id="item.item.id"
          >
          </heap-leach-displayer>
          <span class="remove" @click="removeItem(item.i)" v-if="editable"
            >x</span
          >
        </grid-item>
      </grid-layout>
      <div class="row d-flex justify-content-center">
        <div v-if="editable" class="mr-5 mt-5 mb-5">
          <v-btn dark color="red" @click="cancelEdit"> Cancel </v-btn>
        </div>
        <div v-if="editable" class="ml-5 mt-5 mb-5">
          <v-btn dark color="primary" @click="editDashboard"> save </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import Number from "@/components/DynamicDashboard/Displayers/Number";
import Battery from "@/components/DynamicDashboard/Displayers/Battery";
import Signal from "@/components/DynamicDashboard/Displayers/Signal";
// import Linechart from "@/components/DynamicDashboard/Displayers/Linechart";
// import SparkLine from "@/components/DynamicDashboard/Displayers/SparkLine";

//__________________________________________________
import SlotGraph from "@/components/DynamicDashboard/Displayers/graphs/SlotGraph.vue";
//__________________________________________________

//__________________________________________________
import HeapLeachDisplayer from "./Displayers/HeapLeachDisplayer";
//__________________________________________________

import CompleteDisplayerPreview from "@/components/DynamicDashboard/DisplayerPreview/CompleteDisplayerPreview";

export default {
  name: "DynamicDashboard",
  components: {
    Number,
    Battery,
    Signal,
    SlotGraph,
    CompleteDisplayerPreview,
    HeapLeachDisplayer,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    editable: Boolean,
    dashboardId: Number,
  },
  data() {
    return {
      layout: [],
      colNum: 10,
      index: 0,
      dateFilter: [],
      dashboard: null,
      content: [],
      layoutAux: [],
    };
  },
  watch: {
    dashboardId() {
      this.getDashboard();
    },
  },
  mounted() {
    // this.$gridlayout.load();
    this.index = this.layout.length;
    this.getDashboard();
    this.set48HrsDefault();
  },
  methods: {
    addItem: function (item) {
      let minW;
      let maxH;
      let minH;
      let maxW;

      switch (item.displayModeId) {
        case 1:
          minH = 2;
          minW = 2;
          maxH = 4;
          maxW = 5;
          break;
        case 2:
          minH = 2;
          minW = 2;
          maxH = 4;
          maxW = 5;
          break;
        case 5:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        case 6:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        case 7:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        default:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
      }
      // Add a new item. It must have a unique key!

      this.layout.push({
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 2,
        h: 2,
        i: this.index,
        item: item,
        configuration: {
          minH,
          minW,
          maxH,
          maxW,
        },
      });
      // Increment the counter to ensure key is always unique.
      this.index++;
      this.$toast.success("Displayer has been added to dashboard");
    },
    removeItem: function (val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout.splice(index, 1);
      this.$toast.success("Displayer has been removed from dashboard");
    },
    getDashboard() {
      this.$store
        .dispatch("dashboard/getDashboard", this.dashboardId)
        .then((data) => {
          this.dashboard = data;
          if (this.$route.name === "fakeDashboard" && this.dashboardId === 2) {
            this.dashboard.content.push(
              this.fakeAddHeapLeach(0, 0, 999, 999, "Heap leach test 1")
            );
            this.dashboard.content.push(
              this.fakeAddHeapLeach(3, 0, 998, 998, "Heap leach test 2")
            );
            this.dashboard.content.push(
              this.fakeAddHeapLeach(6, 0, 997, 997, "Heap leach test 3")
            );
            this.dashboard.content.push(
              this.fakeAddHeapLeach(0, 3, 996, 996, "Heap leach test 4")
            );
            this.dashboard.content.push(
              this.fakeAddHeapLeach(3, 3, 995, 995, "Heap leach test 5")
            );
            this.dashboard.content.push(
              this.fakeAddHeapLeach(6, 3, 994, 994, "Heap leach test 6")
            );
          }
          this.layout = data.content;
          if (this.layout.length > 0) {
            this.index = this.layout[this.layout.length - 1].i + 1;
          }
        })
        .catch((err) => console.error(err));
    },
    setPutBodyContent() {
      this.content = this.layout.map((item) => {
        const newItem = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          i: item.i,
          item: {
            id: item.item.id,
            entity: "displayer",
          },
        };
        return newItem;
      });
    },
    editDashboard() {
      this.setPutBodyContent();
      this.$store
        .dispatch("dashboard/editDashboardContent", {
          id: this.dashboard.id,
          content: this.content,
        })
        .then(() => {
          this.$emit("editable");
          this.$toast.success("Dashboard has been edited");
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Something went wrong");
        });
    },
    set48HrsDefault() {
      let from = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      let to = new Date();

      this.dateFilter = [from, to];
    },
    cancelEdit() {
      this.getDashboard();
      this.$emit("editable");
    },
    redirectMonitoring() {
      this.$router.push({ name: "deviceMonitoring", params: { device: 1 } });
    },

    fakeAddHeapLeach(x, y, id, i, name) {
      const newDisplayer = {
        x: x,
        y: y,
        w: 3,
        h: 3,
        i: i,
        item: {
          id: id,
          name: name,
          displayModeId: 11,
          layoutConfiguration: {
            title: "Temperature",
            symbol: "°C",
            icon: "mdi-thermometer",
          },
          slots: [
            {
              id: 1,
              alias: "Temperature",
              deviceId: 1,
              unitOfMeasurementId: 1,
              parameterName: "temp",
              configuration: null,
              deviceIdentifier: "DEMO1",
              deviceAlias: "DEMO 1",
              lastMeasurement: {
                id: 237,
                calibrated_measurement: "11.0000",
                timestamp: "2022-06-28 19:48:45",
              },
            },
          ],
        },
        configuration: { minH: 3, minW: 3, maxH: 3, maxW: 3 },
        moved: false,
      };

      return newDisplayer;
    },
  },
};
</script>
<style >
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
/*************************************/
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.vue-grid-layout {
  background: white;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>