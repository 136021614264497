<template>
  <div class="container-fluid p-2">
    <v-card class="mt-5 p-5">
      <div class="row">
        <div class="col-4">
          <span><b>Name:</b> {{ dashboard.name }}</span>
        </div>
        <div class="col-8">
          <span><b>Description:</b> {{ dashboard.description }}</span>
        </div>
      </div>
      <v-card class="mt-5 p-3" :elevation="0">
        <dynamic-dashboard
          :dashboardId="dashboard.id"
          v-if="dashboard.id"
        ></dynamic-dashboard>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import DynamicDashboard from "@/components/DynamicDashboard/DynamicDashboard";
export default {
  name: "dashboardDetail",
  components: {
    DynamicDashboard,
  },
  data() {
    return {
      dashboard: {},
    };
  },
  methods: {
    getDashboard() {
      this.$store
        .dispatch("dashboard/getDashboard", this.$route.params.dashboard)
        .then((data) => {
          this.dashboard = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getDashboard();
  },
};
</script>

<style>
</style>