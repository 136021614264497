<template>
  <div>
    <v-card elevation="5" class="rounded-lg heap-leach-card">
      <div class="displayer-header">
        <h4 v-text="title"></h4>
      </div>
      <template v-if="timestampI || timestampO">
        <div class="measurement">
          <p class="text-center number">
            <heap-leach-chart
              :input="input"
              :output="output"
              :id="id"
            ></heap-leach-chart>
          </p>
        </div>
        <div class="row w-100">
          <div class="col">
            <p class="text-center timestamp">Input: {{ timestampI }}</p>
          </div>
          <div class="col">
            <p class="text-center timestamp">Output: {{ timestampO }}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="text-center no-data">No data available</p>
      </template>
    </v-card>
  </div>
</template>

<script>
import HeapLeachChart from "./HeapLeachChart";
export default {
  name: "HeapLeachDisplayer",
  components: {
    HeapLeachChart,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {},
  data() {
    return {
      timestampI: "2022-06-28 15:48:31",
      timestampO: "2022-06-28 15:48:31",
      input: "0",
      output: "0",
    };
  },
  methods: {},
  mounted() {
    setInterval(() => {
      this.input = (Math.random() * (10 - 0.5) + 0.5).toFixed(1);
      this.output = (Math.random() * (10 - 0.5) + 0.5).toFixed(1);
    }, 1000);
  },
};
</script>
<style lang="sass">
.heap-leach-card
  padding: 5px
  height: 245px
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  .displayer-header
    display: flex
    align-items: center
    justify-content: center
    .icon
      display: none
      @media screen and ( min-width: 1200px )
        display: block
    h4
      font-size: 17px
      font-weight: 300
      text-align: center
      @media screen and ( min-width: 992px )
        font-size: 17px
        font-weight: 300
        @media screen and ( min-width: 1200px )
          font-size: 20px
          font-weight: 300
    .measurement
      display: flex
      margin: 0
      justify-content: center
      align-items: center
      .number
        margin: 0
        font-size: 25px
        font-weight: 500
        .symbol
          margin: 0
          font-size: 12px
          font-weight: 500
    .timestamp
      font-weight: 200
      font-size: 0.7rem
    .no-data
      margin: 0
</style>
