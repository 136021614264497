<template>
  <div class="entity-actions">
    <tgl-delete-button
      dispatch="dashboard/deleteDashboard"
      :redirect="{
        name: 'workspace',
        params: { tab: 0 },
      }"
      entity="dashboard"
    />
  </div>
</template>

<script>
import TglDeleteButton from "@/components/UI/TglDeleteButton";
export default {
  components: {
    TglDeleteButton,
  },
};
</script>