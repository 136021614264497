<template>
  <div class="container-fluid">
    <div class="row justify-space-between mr-5">
      <tgl-breadcrumbs :items="breadcrumbs" />
      <dashboard-actions
        v-if="$store.getters.getPermission('dashboards_remove')"
      ></dashboard-actions>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <dynamic-dashboards> </dynamic-dashboards>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import DynamicDashboards from "@/components/DynamicDashboard/DynamicDashboards";
import DashboardActions from "@/components/DynamicDashboard/Actions";
import { mapGetters } from "vuex";
export default {
  components: {
    TglBreadcrumbs,
    DynamicDashboards,
    DashboardActions,
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      if (this.$route.params.dashboard) {
        return [
          {
            label: "Espacios de trabajo",
            disabled: false,
            to: { name: "workspace", params: { tab: 0 } },
          },
          {
            label: `Tablero`,
            disabled: true,
            to: { name: "workspace", params: { tab: 0 } },
          },
        ];
      } else {
        return [
          {
            label: "Espacios de trabajo",
            disabled: false,
            to: { name: "workspace", params: { tab: 0 } },
          },
          {
            label: "Tablero",
            disabled: true,
            to: { name: "workspace", params: { tab: 0 } },
          },
        ];
      }
    },
  },
};
</script>