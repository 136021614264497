<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab @click="updateRoute('details')">Details</v-tab>
      <v-tab
        @click="updateRoute('edit')"
        v-if="$store.getters.getPermission('dashboards_edit')"
        >Edit</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <dashboar-details :dashboard="dashboard"></dashboar-details>
      </v-tab-item>
      <v-tab-item>
        <dynamic-dashboard-form></dynamic-dashboard-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DashboarDetails from "@/components/DynamicDashboard/Tabs/DashboarDetails.vue";
import DynamicDashboardForm from "@/components/DynamicDashboard/Crud/DynamicDashboardForm.vue";

export default {
  name: "DynamicDashboard",
  components: {
    DashboarDetails,
    DynamicDashboardForm,
  },
  data() {
    return {
      tab: null,
      dashboard: null,
    };
  },
  watch: {},
  methods: {
    setTab(tabName) {
      switch (tabName) {
        case "details":
          this.tab = 0;
          break;
        case "edit":
          this.tab = 1;
          break;
        default:
          this.tab = 0;
          break;
      }
    },
    setDashboard() {
      this.dashboard = this.$route.params.dashboard;
    },
    updateRoute(path) {
      this.$router.replace(
        `/dashboards/${this.$route.params.dashboard}/${path}`
      );
    },
  },
  mounted() {
    this.setTab(this.$route.params.tab);
    this.setDashboard();
  },
};
</script>

<style>
</style>