<template>
  <svg
    :id="`heap-leach-${id}`"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 520 160"
    height="100"
    width="300"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <rect
      width="261.716"
      height="47.3658"
      rx="0"
      ry="0"
      transform="matrix(.039871 0 0 1 30.88925 96.164295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="261.716"
      height="47.3658"
      rx="0"
      ry="0"
      transform="matrix(.019936 0 0 1.42404 33.49795 13.262295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="261.716"
      height="47.3658"
      rx="0"
      ry="0"
      transform="matrix(-.000128-.019936 6.31281-.040633 33.53145 16.833295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="261.716"
      height="47.3658"
      rx="0"
      ry="0"
      transform="matrix(-.000421 0.037496 9.67445 0.108627 30.87325 136.050296)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="261.716"
      height="47.3658"
      rx="0"
      ry="0"
      transform="matrix(.039871 0 0 1 478.67635 96.164295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <ellipse
      rx="28.4094"
      ry="28.4094"
      transform="translate(483.89335 101.676295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <ellipse
      rx="28.4094"
      ry="28.4094"
      transform="translate(36.10665 101.676295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <path
      d="M124.515805,321.123937l64.626192-61.816357l261.716006-.000001l64.626192,61.816357-390.96839.000001Z"
      transform="translate(-59.9997-177.594204)"
      fill="#d2dbed"
      stroke-width="1.28"
    />
    <path
      d="M124.515805,321.123937l64.626192-61.816357l261.716006-.000001l64.626192,61.816357-390.96839.000001Z"
      transform="matrix(.548257 0 0 0.70803 84.55835-146.652084)"
      fill="#d2dbed"
      stroke-width="1.28"
    />
    <rect
      width="64"
      height="48"
      rx="0"
      ry="0"
      transform="matrix(.206282 0 0 0.177966 210.58735 8.991295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="64"
      height="48"
      rx="0"
      ry="0"
      transform="matrix(.206282 0 0 0.177966 257.41335 9.691295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <rect
      width="64"
      height="48"
      rx="0"
      ry="0"
      transform="matrix(.206282 0 0 0.177966 307.08935 8.991295)"
      fill="#d2dbed"
      stroke-width="0"
    />
    <text
      dx="0"
      dy="0"
      font-size="17"
      font-weight="400"
      transform="translate(12.321679 107.500527)"
      stroke-width="0"
      :id="`input-${id}`"
    >
      00.00
    </text>
    <text
      dx="0"
      dy="0"
      font-size="17"
      font-weight="400"
      transform="translate(457.108819 107.500527)"
      stroke-width="0"
      :id="`output-${id}`"
    >
      00.00
    </text>
  </svg>
</template>
<script>
export default {
  name: "HeapLeachChart",
  data: () => ({}),
  props: {
    input: {
      type: String,
      required: true,
    },
    output: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    nestedInput() {
      return this.input;
    },
    nestedOutput() {
      return this.output;
    },
  },
  watch: {
    input: {
      handler() {
        const input = document.getElementById(`input-${this.id}`);
        input.innerHTML = `I: ${this.nestedInput}`;
      },
    },
    output: {
      handler() {
        const output = document.getElementById(`output-${this.id}`);
        output.innerHTML = `O: ${this.nestedOutput}`;
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass">
</style>